html,
body {
    height: 100%;
    margin: 0;
    background-color: #1e1e1e;
    color: #d4d4d4;
    /* font-family: 'Courier New', Courier, monospace; */
    font-family: 'Source Code Pro', monospace;
}

canvas {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    background-color: transparent;
}

a {
    color: #6582ed;
    text-decoration: none;
    margin: 0 10px;
    font-weight: 900;
}

a:hover {
    text-decoration: underline;
}