html, body {
  color: #d4d4d4;
  background-color: #1e1e1e;
  height: 100%;
  margin: 0;
  font-family: Source Code Pro, monospace;
}

canvas {
  background-color: #0000;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
}

a {
  color: #6582ed;
  margin: 0 10px;
  font-weight: 900;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
/*# sourceMappingURL=index.9ac10c23.css.map */
